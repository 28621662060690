import { GatsbyGraphQLType, graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import { Link } from "gatsby";

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

interface Props {
  data: GatsbyGraphQLType;
}
const IndexPage: React.FC<Props> = ({ data }) => {
  const { title, description } = data.site.siteMetadata;
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h1>{title}</h1>
          </header>
          <h2>Welcome!</h2>
          <p>
            This is my personal website which will be used to document a few of
            my software projects.
          </p>
        </section>

        <section id="two">
          <h2>Projects</h2>
          <ul className="actions">
            <li>
              <Link to="projects/table" className="button">
                Table
              </Link>
            </li>
            <li>
              <Link to="projects/nuclear-engine" className="button">
                NuclearEngine
              </Link>
            </li>
            <li>
              <Link to="projects/movee" className="button">
                Movee
              </Link>
            </li>
          </ul>
        </section>

        <section id="three">
          <header>
            <h2>Resume</h2>
          </header>
          <p>🚧👷 Under construction 👷🚧</p>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
